import * as React from "react";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/banner";
import BlurbMenu from "../components/blurb-menu";
import Subscribe from "../components/subscribe";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.nodeArticle;
  const banner = post?.relationships?.field_article_banner;
  const siteTitle = data.site.siteMetadata?.title || ``;
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        location={location}
        description={post.body?.summary || null}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Banner
          title={banner?.field_banner_heading || post?.title}
          props={banner || post}
        />
        <BlurbMenu props={post} menuProp="posts" />
      </article>
      <nav className="bg-gray-50">
        <ul className="flex flex-wrap justify-between list-none container pb-12">
          <li>
            {previous && (
              <Link
                to={
                  previous.path?.alias || "/post/" + kebabCase(previous.title)
                }
                rel="prev"
              >
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.path?.alias || "/post/" + kebabCase(next.title)}
                rel="next"
              >
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <Subscribe hasTestimonial={false} />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    nodeArticle(id: { eq: $id }) {
      id
      title
      body {
        processed
        summary
      }
      changed(formatString: "MMMM DD, YYYY")
      relationships {
        # field_article_banner {
        #   field_banner_link_label
        #   relationships {
        #     field_banner_link {
        #       path {
        #         alias
        #       }
        #     }
        #     field_banner_image {
        #       field_media_image {
        #         alt
        #       }
        #       relationships {
        #         field_media_image {
        #           localFile {
        #             childImageSharp {
        #               gatsbyImageData
        #             }
        #             publicURL
        #           }
        #         }
        #       }
        #     }
        #   }
        #   field_banner_heading
        #   field_banner_image_focus
        #   field_banner_content {
        #     processed
        #   }
        # }
        field_categories {
          name
        }
        field_image {
          ... on media__image {
            field_media_image {
              alt
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1024)
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    previous: nodeArticle(id: { eq: $previousPostId }) {
      title
      path {
        alias
      }
    }
    next: nodeArticle(id: { eq: $nextPostId }) {
      title
      path {
        alias
      }
    }
  }
`;
// date(formatString: "MMMM DD, YYYY")
// description
// excerpt(pruneLength: 160)
